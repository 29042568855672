import { useRecoilState, useSetRecoilState } from "recoil";
import ModalBase from "./Base";
import {
  atom__officeModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useCallback, useEffect, useRef, useState } from "react";
import CloseCircleButton from "../Button/CloseCircle";
import BaseButton from "../Button/Base";
import { regNumber } from "../../lib/validator";
import {
  api_officeCreate,
  api_officeDetail,
  api_officeUpdate,
} from "../../api/office";
import { api_fileUploadCommit, api_fileUploadPrepare } from "../../api/file";
import iconImage from "../../assets/image.png";

const ModalOffice = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(atom__officeModalInfo);
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [registerNum, setRegisterNum] = useState("");
  const [ceoName, setCeoName] = useState("");
  const [hp1, setHp1] = useState("");
  const [hp2, setHp2] = useState("");
  const [hp3, setHp3] = useState("");
  const [introduce, setIntroduce] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [selectedLogo, setSelectedLogo] = useState<{
    url: string;
    file?: File;
  } | null>(null);

  const [canNext, setCanNext] = useState(false);

  const refFileInput = useRef<HTMLInputElement>(null);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  useEffect(() => {
    if (!r__modalInfo) {
      setAddress("");
      setRegisterNum("");
      setName("");
      setCeoName("");
      setHp1("");
      setHp2("");
      setHp3("");
      setIntroduce("");
      setSelectedLogo(null);
      setLogoUrl("");
      return;
    }

    if (!r__modalInfo.officeId) return;

    // 상세 api
    api_officeDetail(r__modalInfo.officeId).then((res) => {
      if (!res) return;

      setAddress(res.office.address);
      setRegisterNum(res.office.registerNumber);
      setName(res.office.name);
      setCeoName(res.office.ceo);
      if (res.office.hp) {
        const [_1, _2, _3] = res.office.hp.split("-");
        setHp1(_1);
        setHp2(_2);
        setHp3(_3);
      }
      setIntroduce(res.office.introduce ?? "");
      if (res.office.logoUrl) setSelectedLogo({ url: res.office.logoUrl });
      setLogoUrl(res.office.logoUrl ?? "");
    });
  }, [r__modalInfo]);

  useEffect(() => {
    setCanNext(
      name.length > 3 &&
        address.length > 8 &&
        registerNum.length > 2 &&
        ceoName.length >= 2
    );
  }, [name, address, registerNum, ceoName]);

  const create = async () => {
    let changingLogoUrl: string | null = null;
    if (selectedLogo?.file) {
      const prepareRes = await api_fileUploadPrepare(selectedLogo.file.name);
      if (!prepareRes) return;

      const {
        id: preparedFileId,
        signedUrl,
        url,
      } = prepareRes.preparedFiles[0];
      // s3
      const resS3 = await fetch(
        new Request(signedUrl, {
          method: "PUT",
          body: selectedLogo.file,
          headers: new Headers({
            "Content-Type": selectedLogo.file.type,
          }),
        })
      );
      if (resS3.status !== 200)
        return alert("죄송합니다. 잠시 후 다시 이용해 주세요.");

      await api_fileUploadCommit(preparedFileId);

      changingLogoUrl = url;
    }

    const fullHp =
      hp1.length > 1 && hp2.length > 2 && hp3.length > 2
        ? `${hp1}-${hp2}-${hp3}`
        : null;
    const res = await api_officeCreate({
      name,
      registerNumber: registerNum,
      address,
      ceo: ceoName,
      hp: fullHp,
      introduce: introduce || null,
      logoUrl: logoUrl || null,
    });
    if (!res) return;

    r__modalInfo?.onCreate?.();
    close();

    r__setSuccessModalInfo({
      desc: ["사무소가 생성되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });
  };
  const edit = async () => {
    if (!r__modalInfo?.officeId) return;

    let changingLogoUrl: string | null = selectedLogo?.url || null;
    // 기존 로고가 있었다면 selectedLogo는 {url: string} 형식임. selectedLogo가 falsy한건 기존사진을 지웠기 때문이므로 changingLogoUrl을 null로.
    if (!selectedLogo) changingLogoUrl = null;
    else if (selectedLogo.file) {
      const prepareRes = await api_fileUploadPrepare(selectedLogo.file.name);
      if (!prepareRes) return;

      const {
        id: preparedFileId,
        signedUrl,
        url,
      } = prepareRes.preparedFiles[0];
      // s3
      const resS3 = await fetch(
        new Request(signedUrl, {
          method: "PUT",
          body: selectedLogo.file,
          headers: new Headers({
            "Content-Type": selectedLogo.file.type,
          }),
        })
      );
      if (resS3.status !== 200)
        return alert("죄송합니다. 잠시 후 다시 이용해 주세요.");

      await api_fileUploadCommit(preparedFileId);

      changingLogoUrl = url;
    }

    const fullHp =
      hp1.length > 1 && hp2.length > 2 && hp3.length > 2
        ? `${hp1}-${hp2}-${hp3}`
        : null;
    const res = await api_officeUpdate(r__modalInfo.officeId, {
      name,
      registerNumber: registerNum,
      address,
      ceo: ceoName,
      hp: fullHp,
      introduce: introduce || null,
      logoUrl: changingLogoUrl,
    });
    if (!res) return;

    r__modalInfo?.onEdit?.();
    close();

    r__setSuccessModalInfo({
      desc: ["사무소 정보가 수정되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>{r__modalInfo?.officeId ? "사무소 정보" : "신규 사무소 생성"}</h2>
        <CloseCircleButton onClick={close} />
      </div>

      <label htmlFor="name">사무소명</label>
      <input
        id="name"
        type="text"
        placeholder="사무소명 입력"
        maxLength={50}
        style={{ marginBottom: "20px" }}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />

      <label htmlFor="address">등록주소</label>
      <input
        id="address"
        type="text"
        placeholder="주소 입력"
        maxLength={100}
        style={{ marginBottom: "20px" }}
        value={address}
        onChange={(e) => {
          setAddress(e.target.value);
        }}
      />

      <label htmlFor="registerNum">중개사무소 등록번호</label>
      <input
        id="registerNum"
        type="text"
        placeholder="'-' 포함하여 전체 입력"
        maxLength={30}
        style={{ marginBottom: "20px" }}
        value={registerNum}
        onChange={(e) => {
          setRegisterNum(e.target.value);
        }}
      />

      <label htmlFor="ceoName">대표자</label>
      <input
        id="ceoName"
        type="text"
        placeholder="이름 입력"
        maxLength={30}
        style={{ marginBottom: "20px" }}
        value={ceoName}
        onChange={(e) => {
          setCeoName(e.target.value);
        }}
      />

      <label htmlFor="hp1">대표번호 (유선)</label>
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <input
          id="hp1"
          type="text"
          placeholder="031"
          maxLength={3}
          style={{ textAlign: "center", flex: 1, maxWidth: "100px" }}
          value={hp1}
          onChange={(e) => {
            if (e.target.value.length && !regNumber.test(e.target.value))
              return;
            setHp1(e.target.value);
          }}
        />

        <input
          id="hp2"
          type="text"
          placeholder="1234"
          maxLength={4}
          style={{ textAlign: "center", flex: 1, maxWidth: "100px" }}
          value={hp2}
          onChange={(e) => {
            if (e.target.value.length && !regNumber.test(e.target.value))
              return;
            setHp2(e.target.value);
          }}
        />

        <input
          id="hp3"
          type="text"
          placeholder="1234"
          maxLength={4}
          style={{ textAlign: "center", flex: 1, maxWidth: "100px" }}
          value={hp3}
          onChange={(e) => {
            if (e.target.value.length && !regNumber.test(e.target.value))
              return;
            setHp3(e.target.value);
          }}
        />
      </div>

      <label>로고 이미지 (선택)</label>
      <div style={{ position: "relative", marginBottom: "20px" }}>
        <input
          type="file"
          ref={refFileInput}
          accept={"image/png, image/jpeg, image/jpg"}
          style={{
            position: "absolute",
            top: 10,
            left: 10,
            width: "10px",
            height: "10px",
          }}
          onChange={(e) => {
            if (!e.target.files?.length) {
              setSelectedLogo(null);
              return;
            }
            const f = e.target.files[0];
            const localUrl = URL.createObjectURL(f);
            setSelectedLogo({ url: localUrl, file: f });
          }}
        />
        <div
          style={{
            position: "relative",
            borderRadius: "8px",
            border: "1px solid #000",
            backgroundColor: "#fff",
            overflow: "hidden",
            width: "100%",
            maxWidth: "450px",
            aspectRatio: 450 / 300,
            cursor: "pointer",
          }}
          onClick={() => refFileInput.current?.click()}
        >
          {selectedLogo?.url ? (
            <img
              // src={image.url}
              src={selectedLogo.url}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          ) : (
            <div
              className="flex-col-center-center"
              style={{ width: "100%", height: "100%" }}
            >
              <img src={iconImage} style={{ width: "24px", height: "24px" }} />
              <p className="modal-desc" style={{ marginTop: "24px" }}>
                클릭하여 사진 업로드
              </p>
            </div>
          )}
        </div>
      </div>

      <label>소개글 (선택)</label>
      <textarea
        value={introduce}
        onChange={(e) => setIntroduce(e.target.value)}
        placeholder="소개글 입력"
        style={{ marginBottom: "20px", height: "200px" }}
      />

      <BaseButton
        text={r__modalInfo?.officeId ? "수정" : "생성"}
        onClick={r__modalInfo?.officeId ? edit : create}
        disabled={!canNext}
      />
    </ModalBase>
  );
};

export default ModalOffice;
