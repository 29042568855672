interface IProps {
  label: string;
  checked: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
}
const Checkbox = (props: IProps) => {
  return (
    <div
      className="flex-row-center-center checkbox-container"
      onClick={props.onClick}
      style={props.style}
    >
      <span className={`checkbox ${props.checked ? "checked" : ""}`}></span>
      <span className="label text-regular">{props.label}</span>
    </div>
  );
};

export default Checkbox;
