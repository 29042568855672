/* eslint-disable react-hooks/exhaustive-deps */
import { useRecoilState } from "recoil";
import BaseButtonOutline from "../components/Button/Outline";
import { atom__me } from "../lib/recoil/common.atom";
import { useEffect, useState } from "react";
import logo from "../assets/logo_275w.png";
import { useNavigate } from "react-router-dom";
import { api_login } from "../api/auth";

export const LoginPage = () => {
  const navigate = useNavigate();

  const [r__me, r__setMe] = useRecoilState(atom__me);

  const [uid, setUid] = useState("");
  const [pw, setPw] = useState("");

  useEffect(() => {
    if (!!r__me) {
      alert("이미 로그인 되어있습니다.");
      navigate("/");
    }
  }, []);

  const login = async () => {
    api_login({ uid, pw }).then((res) => {
      if (!res) return;

      r__setMe(res.admin);
      window.location.replace("/");
    });
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: "60px 0",
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "478px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <img
          src={logo}
          style={{
            width: "100%",
            objectFit: "contain",
          }}
        />
        <div style={{ padding: "0 50px" }}>
          <label htmlFor="uid">아이디</label>
          <input
            id="uid"
            type="text"
            placeholder="아이디 입력"
            style={{ marginBottom: "20px" }}
            onChange={(e) => {
              setUid(e.target.value);
            }}
          />
          <label htmlFor="pw">비밀번호</label>
          <input
            id="pw"
            type="password"
            placeholder="비밀번호 입력"
            onChange={(e) => {
              setPw(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") login();
            }}
          />
        </div>

        <BaseButtonOutline text={"로그인"} disabled={false} onClick={login} />
      </div>
    </div>
  );
};
