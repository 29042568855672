import { useState } from "react";
import search from "../assets/search.png";

interface IProps {
  onClickSearch: (keyword: string) => void;
  placeholder?: string;
}

const SearchInputWithoutAttr = (props: IProps) => {
  const [keyword, setKeyword] = useState("");

  return (
    <div className="flex-row-center-center search-container">
      <input
        className="search-input"
        style={{ flex: 1 }}
        placeholder={props.placeholder}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        onKeyDown={(e) => {
          if (e.code === "Enter") props.onClickSearch(keyword);
        }}
      />
      <img
        src={search}
        onClick={() => props.onClickSearch(keyword)}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export default SearchInputWithoutAttr;
