import { TListQuery } from "../lib/types/common.type";
import { TDeposit, TDepositField } from "../lib/types/deposit.type";
import axios from "./axios";

export const api_depositCreate = async (
  payload: Omit<TDepositField, "createAdmId" | "createAdmName">
) => {
  const res = await axios.post("/deposit", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_depositList = async (query: TListQuery) => {
  const res = await axios.get("/deposit", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TDeposit[]; totalCount?: number };
};
