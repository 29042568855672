interface IProps {
  text: string;
  sort?: "asc" | "desc" | "none";
  width?: number;
  textSmall?: boolean;
}
const Th = (props: IProps) => {
  return (
    <th
      className={props.textSmall ? "small" : ""}
      style={{ width: props.width ? props.width + "px" : undefined }}
    >
      <span>{props.text}</span>
    </th>
  );
};

export default Th;
