import { TListQuery } from "../lib/types/common.type";
import {
  EPostStatus,
  EPostTradeStatus,
  TPost,
  TPostListItem,
  TPostPendingListItem,
} from "../lib/types/post.type";
import axios from "./axios";

export const api_postList = async (
  query: TListQuery & {
    withTradeIng: "y" | "n";
    withTradeDone: "y" | "n";
    status?: EPostStatus;
  }
) => {
  const res = await axios.get("/post", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TPost[]; totalCount?: number };
};

export const api_postDetail = async (id: string) => {
  const res = await axios.get("/post/" + id);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { post: TPost & { badgeWorth: number } };
};

export const api_postAccept = async (id: string) => {
  const res = await axios.post(`/post/${id}/accept`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};
export const api_postReject = async (
  id: string,
  payload: { rejectReason: string }
) => {
  const res = await axios.post(`/post/${id}/reject`, payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};

export const api_postActivate = async (id: string, isActive: boolean) => {
  const res = await axios.post(`/post/${id}/activate`, { isActive });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { newTradeStatus?: EPostTradeStatus };
};

export const api_postTradeDoneConfirm = async (id: string) => {
  const res = await axios.post(`/post/${id}/done`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};
