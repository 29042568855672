import { useRecoilState } from "recoil";
import { atom__me } from "../lib/recoil/common.atom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { api_logout } from "../api/auth";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const [r__me, r__setMe] = useRecoilState(atom__me);

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleClickForMenuClose = () => {
      setShowMenu(false);
    };
    document.addEventListener("click", handleClickForMenuClose);

    return () => {
      document.removeEventListener("click", handleClickForMenuClose);
    };
  }, []);

  const logout = () => {
    api_logout().then((res) => {
      if (!res) return;
      r__setMe(null);
      window.localStorage.removeItem("at-admin");
      window.location.replace("/");
    });
  };

  return (
    <header>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu((prev) => !prev);
        }}
        style={{
          position: "relative",
          width: "250px",
          height: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid #e8e8e8",
          borderRadius: "8px",
          borderBottomLeftRadius: showMenu ? undefined : "8px",
          borderBottomRightRadius: showMenu ? undefined : "8px",
          padding: "0 20px",
          marginRight: "20px",
          cursor: "pointer",
        }}
      >
        <span style={{ marginRight: "20px" }}>{r__me?.name}님, 안녕하세요</span>
        {showMenu ? (
          <FontAwesomeIcon icon={faCaretUp} />
        ) : (
          <FontAwesomeIcon icon={faCaretDown} />
        )}
        {showMenu && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: -1,
              width: "250px",
              border: "1px solid #e8e8e8",
              backgroundColor: "#fff",
            }}
          >
            <button
              className="button-hover"
              style={{
                width: "100%",
                height: "40px",
                backgroundColor: "#fff",
                borderBottom: "1px solid #eee",
                color: "#000",
              }}
              onClick={(e) => {
                e.stopPropagation();
                logout();
              }}
            >
              로그아웃
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
