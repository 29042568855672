import { IButtonCommonProps } from "./Base";

interface IProps extends IButtonCommonProps {}

const BaseButtonOutline = (props: IProps) => {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className={`button-base-outline ${props.class} ${
        props.disabled ? "button-base-outline-disabled" : ""
      }`}
      style={props.style}
    >
      {props.text}
    </button>
  );
};

export default BaseButtonOutline;
