import { Outlet, useNavigate } from "react-router-dom";
import SNB from "./SNB";
import Header from "./Header";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { api_me } from "../api/auth";
import ModalAdminCreate from "./Modal/AdminCreate";
import ModalSalesCreate from "./Modal/SalesCreate";
import ModalAlert from "./Modal/Alert";
import ModalConfirm from "./Modal/Confirm";
import ModalSuccess from "./Modal/Success";
import ModalPaperView from "./Modal/PaperView";
import ModalUserReject from "./Modal/UserReject";
import ModalSearchOffice from "./Modal/SearchOffice";
import ModalComplaint from "./Modal/Complaint";
import { atom__me } from "../lib/recoil/common.atom";
import ModalMembership from "./Modal/Membership";
import ModalOffice from "./Modal/Office";
import ModalDepositCreate from "./Modal/DepositCreate";
import ModalBanner from "./Modal/Banner";
import ModalNewsEdit from "./Modal/NewsEdit";
import ModalNotice from "./Modal/NoticeModal";
import ModalPostPhone from "./Modal/PostPhone";
import ModalPostReject from "./Modal/PostReject";
import ModalPostDetail from "./Modal/PostDetail";
import Loading from "./Modal/Loading";

const Layout = () => {
  const navigate = useNavigate();

  const [r__me, r__setMe] = useRecoilState(atom__me);

  useEffect(() => {
    if (r__me) return;

    api_me()
      .then((res) => {
        if (!res?.admin) return navigate("/login");
        r__setMe(res.admin);
      })
      .catch((e) => {
        navigate("/login");
      });
  }, []);
  return (
    <div id="root">
      <SNB />
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Header />
        <main>
          <Outlet />
        </main>
      </div>

      {/* Modals */}

      <ModalAdminCreate />
      <ModalSalesCreate />

      <ModalPaperView />
      <ModalUserReject />
      <ModalSearchOffice />
      <ModalComplaint />
      <ModalMembership />

      <ModalOffice />

      <ModalDepositCreate />

      <ModalBanner />
      <ModalNewsEdit />
      <ModalNotice />

      <ModalPostPhone />
      <ModalPostReject />
      <ModalPostDetail />

      <Loading />

      <ModalConfirm />
      <ModalAlert />
      <ModalSuccess />
    </div>
  );
};

export default Layout;
