import { useRecoilValue } from "recoil";
import { atom__loading } from "../../lib/recoil/modal.atom";

const Loading = () => {
  const r__loading = useRecoilValue(atom__loading);

  if (!r__loading) return null;

  return (
    <div
      className="flex-row-center-center"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <div
        className="text-medium bold"
        style={{
          borderRadius: "8px",
          backgroundColor: "var(--yellow-dark)",
          padding: "30px 40px",
          boxShadow: "2px 3px 5px #77777777",
          color: "#fff",
          cursor: "default",
        }}
      >
        처리중입니다...
      </div>
    </div>
  );
};

export default Loading;
