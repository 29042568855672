import { useNavigate } from "react-router-dom";
import BaseButton from "../components/Button/Base";

const NotFountPage = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: "60px 0",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "478px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
          페이지를 찾을 수 없습니다.
        </h1>
        <p style={{ textAlign: "center", marginBottom: "60px" }}>
          확인 버튼 클릭 시 첫페이지로 돌아갑니다.
        </p>

        <BaseButton
          text="확인"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
    </div>
  );
};

export default NotFountPage;
