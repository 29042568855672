import { useRecoilState, useSetRecoilState } from "recoil";
import ModalBase from "./Base";
import {
  atom__confirmModalInfo,
  atom__membershipModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useCallback, useEffect, useState } from "react";
import CloseCircleButton from "../Button/CloseCircle";
import BaseButton from "../Button/Base";
import {
  api_membershipAccept,
  api_membershipPendingDetail,
} from "../../api/membership";

const ModalMembership = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__membershipModalInfo
  );
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);

  const [uid, setUid] = useState("asdfsadf");
  const [userName, setUserName] = useState("asdfasdfasdf");
  const [currentMembershipName, setCurrentMembershipName] = useState("-");
  const [currentMembershipTerm, setCurrentMembershipTerm] = useState("");

  const [applyingMembershipName, setApplyingMembershipName] = useState("");
  const [startDate, setStartDate] = useState("");

  useEffect(() => {
    if (!r__modalInfo) {
      setUid("");
      setUserName("");
      setCurrentMembershipName("-");
      setCurrentMembershipTerm("");
      setApplyingMembershipName("");
      setStartDate("");
      return;
    }

    api_membershipPendingDetail(r__modalInfo.membershipId).then((res) => {
      if (!res) return;

      setUid(res.userMembership.user.uid);
      setUserName(res.userMembership.user.name);
      if (res.userMembership.user.activeMembership) {
        setCurrentMembershipName(
          res.userMembership.user.activeMembership.membershipInfo.name
        );
        const { startDate, endDate } = res.userMembership.user.activeMembership;
        if (startDate && endDate)
          setCurrentMembershipTerm(
            `${startDate.replaceAll("-", ".")} - ${endDate.replaceAll(
              "-",
              "."
            )}`
          );
      }

      setApplyingMembershipName(res.userMembership.membershipInfo.name);
    });
  }, [r__modalInfo]);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  const confirmApply = () => {
    r__setConfirmModalInfo({
      title: "멤버십 적용",
      desc: ["멤버십이 적용됩니다.", "버튼을 눌러 최종승인을 진행해주세요."],
      onClickBtnLeft: () => {
        r__setConfirmModalInfo(null);
      },
      onClickBtnRight: apply,
      btnRightText: "적용",
      theme: "info",
    });
  };

  const apply = async () => {
    if (!r__modalInfo) return;
    const res = await api_membershipAccept(r__modalInfo.membershipId, {
      startDate,
    });
    if (!res) return;

    close();

    r__modalInfo.onAccept?.();

    r__setConfirmModalInfo(null);
    r__setSuccessModalInfo({
      desc: ["멤버십 적용이 성공적으로 완료되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>멤버십 적용</h2>
        <CloseCircleButton onClick={close} />
      </div>

      <p
        className="modal-desc"
        style={{ textAlign: "left", marginTop: "30px" }}
      >
        아이디
      </p>
      <p className="value-big">{uid}</p>

      <p
        className="modal-desc"
        style={{ textAlign: "left", marginTop: "30px" }}
      >
        이름
      </p>
      <p className="value-big">{userName}</p>

      <p
        className="modal-desc"
        style={{ textAlign: "left", marginTop: "30px" }}
      >
        기존 멤버십
      </p>
      <div className="flex-row-between-center">
        <p className="value-big">{currentMembershipName}</p>
        <p className="value-small">{currentMembershipTerm}</p>
      </div>

      <p
        className="modal-desc"
        style={{ textAlign: "left", marginTop: "30px" }}
      >
        요청 멤버십
      </p>
      <p className="value-big">{applyingMembershipName}</p>

      <p
        className="modal-desc"
        style={{ textAlign: "left", marginTop: "30px" }}
      >
        시작일
      </p>
      <input
        type="date"
        value={startDate}
        onChange={(e) => {
          const value = e.target.value;
          setStartDate(value);
        }}
        style={{ marginBottom: "30px" }}
      />

      <BaseButton text="적용" onClick={confirmApply} disabled={!startDate} />
    </ModalBase>
  );
};

export default ModalMembership;
