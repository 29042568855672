import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import { printFormattedDate } from "../../lib/util";
import SmallButton from "../../components/Button/Small";
import { useSetRecoilState } from "recoil";
import {
  atom__confirmModalInfo,
  atom__newsEditModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useNavigate } from "react-router-dom";
import { api_newsDelete, api_newsList } from "../../api/site";
import { TNews } from "../../lib/types/site.type";
import Pagination from "../../components/Table/Pagination";

const NewsListPage = () => {
  const navigate = useNavigate();

  const r__setNewsEditModalInfo = useSetRecoilState(atom__newsEditModalInfo);
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TNews[]>([]);

  const limit = useMemo(() => 10, []);
  const refSearchAttribute = useRef("");
  const refSearchKeyword = useRef("");

  useEffect(() => {
    _fetch({ page: 1, needTotalCount: "y" });
  }, []);

  const deleteNews = async (id: string) => {
    const res = await api_newsDelete([id]);
    if (!res) return;

    r__setConfirmModalInfo(null);
    r__setSuccessModalInfo({
      title: "삭제",
      desc: ["삭제가 완료되었습니다."],
    });

    _fetch({ page: currentPage, needTotalCount: "y" });
  };

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
  }) => {
    api_newsList({
      from: limit * (page - 1),
      limit,
      searchField: refSearchAttribute.current || undefined,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>뉴스</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {
              refSearchAttribute.current = attribute;
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            attributes={["지역", "출처"]}
          />
        </div>

        <SmallButton
          text="등록"
          onClick={() => {
            navigate("/site/news/register");
          }}
          width={100}
          color="beige"
        />
      </div>

      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <Th text="지역" sort="none" width={150} />
            <Th text="등록일" sort="none" width={150} />
            <Th text="출처" sort="none" width={150} />
            <Th text="제목" sort="none" />
            <Th text="URL" sort="none" width={200} />
            <Th text="관리" sort="none" />
          </thead>
          <tbody>
            {list.map((news) => {
              return (
                <tr>
                  <td>{news.region}</td>
                  <td>
                    {printFormattedDate(new Date(news.createdAt), {
                      seperator: ".",
                    })}
                  </td>
                  <td>{news.source}</td>
                  <td>{news.subject}</td>
                  <td>
                    <p className="ellipsis" style={{ width: "200px" }}>
                      {news.url}
                    </p>
                  </td>

                  <td>
                    <div
                      className="flex-row-center-center"
                      style={{ gap: "10px" }}
                    >
                      <SmallButton
                        text="수정"
                        color="yellow-dark"
                        width={64}
                        onClick={() => {
                          r__setNewsEditModalInfo({
                            newsId: news.id,
                            onEdit: () => _fetch({ page: currentPage }),
                          });
                        }}
                      />

                      <SmallButton
                        text="삭제"
                        color="tomato"
                        width={64}
                        onClick={() => {
                          r__setConfirmModalInfo({
                            title: "삭제",
                            desc: ["해당 뉴스를 삭제하시겠습니까?"],
                            onClickBtnLeft: () => r__setConfirmModalInfo(null),
                            onClickBtnRight: () => {
                              deleteNews(news.id);
                            },
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default NewsListPage;
