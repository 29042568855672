import { TDefaultEntity } from "./common.type";
import { TUser } from "./user.type";

export enum EMembershipKind {
  NORMAL = "normal",
  PROMOTION = "promotion",
}
export type TMembershipInfo = TDefaultEntity & {
  name: string;
  amount: number;
  term: number;
  kind: EMembershipKind;
  available: boolean;
  sequence: number;
};

/** 이용자가 신청한 멤버십 상태 */
export enum EUserMembershipStatus {
  /** 승인대기 */
  PENDING = "pending",
  /** 승인되어 효력을 발휘하는중 */
  APPROVE = "approve",
  /** 거절됨 */
  REJECT = "reject",
  /** 계정 이용정지 등에 따른 일시중지 */
  PAUSED = "paused",
  /** 환불에 따른 영구 중지 */
  REFUND = "refund",
  /** 환불신청됨 */
  REFUND_PENDING = "refund_pending",
  /** 만료됨 */
  EXPIRED = "expired",
}
export type TUserMembership = {
  id: string;
  createdAt: string;

  membershipId: string;
  membershipInfo: TMembershipInfo;
  userId: string;
  user: TUser;

  startDate: string | null;
  endDate: string | null;
  status: EUserMembershipStatus;
  restDaysBeforePaused: number | null;
  refundRequestedAt: string | null;
};
