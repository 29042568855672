import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import SmallButton from "../../components/Button/Small";
import { useSetRecoilState } from "recoil";
import {
  atom__confirmModalInfo,
  atom__postDetailModalInfo,
  atom__postPhoneModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import Pagination from "../../components/Table/Pagination";
import {
  api_postActivate,
  api_postList,
  api_postTradeDoneConfirm,
} from "../../api/post";
import {
  EPostStatus,
  EPostTradeStatus,
  TPost,
} from "../../lib/types/post.type";
import Checkbox from "../../components/Checkbox";
import Switch from "../../components/Switch";

const PostListPage = () => {
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);
  const r__setPostDetailModalInfo = useSetRecoilState(
    atom__postDetailModalInfo
  );
  const r__setPostPhoneModalInfo = useSetRecoilState(atom__postPhoneModalInfo);

  const [withTradeIng, setWithTradeIng] = useState(true);
  const [withTradeDone, setWithTradeDone] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TPost[]>([]);

  const limit = useMemo(() => 10, []);
  const refSearchAttribute = useRef("");
  const refSearchKeyword = useRef("");

  const refToggleWithTradeIng = useRef(true);
  const refToggleWithTradeDone = useRef(true);

  useEffect(() => {
    _fetch({
      page: 1,
      needTotalCount: "y",
    });
  }, []);

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
    userKind?: string;
  }) => {
    api_postList({
      from: limit * (page - 1),
      limit,
      searchField: refSearchAttribute.current || undefined,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
      status: EPostStatus.ACCEPT,
      withTradeIng: refToggleWithTradeIng.current ? "y" : "n",
      withTradeDone: refToggleWithTradeDone.current ? "y" : "n",
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  /** 거래완료 처리 */
  const done = async (id: string) => {
    const res = await api_postTradeDoneConfirm(id);
    if (!res) return;

    r__setConfirmModalInfo(null);
    r__setSuccessModalInfo({ desc: ["거래완료 처리되었습니다."] });

    setCurrentPage(1);
    _fetch({ page: 1, needTotalCount: "y" });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>매물 목록</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {
              refSearchAttribute.current = attribute;
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            attributes={["아파트명", "중개사무소", "등록인"]}
          />
        </div>

        <div className="flex-row-center-center">
          <Checkbox
            label="거래중"
            checked={withTradeIng}
            onClick={() => {
              const prev = refToggleWithTradeIng.current;
              setWithTradeIng(!prev);
              refToggleWithTradeIng.current = !prev;
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
          />
          <Checkbox
            label="거래완료"
            checked={withTradeDone}
            onClick={() => {
              const prev = refToggleWithTradeDone.current;
              setWithTradeDone(!prev);
              refToggleWithTradeDone.current = !prev;
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            style={{ margin: "0 10px" }}
          />
        </div>
      </div>

      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <Th text="아파트명" sort="none" width={200} />
            <Th text="동 / 호수" sort="none" width={150} />
            <Th text="중개사무소" sort="none" width={200} />
            <Th text="등록인" sort="none" />
            <Th text="전화번호" sort="none" width={150} />
            <Th text="거래현황" sort="none" width={150} />
            <Th text="관리" sort="none" />
          </thead>
          <tbody>
            {list.map((post, i) => {
              return (
                <tr key={post.id}>
                  <td className="small">{post.apart.kaptName}</td>
                  <td>
                    {post.dong}동 / {post.ho}호
                  </td>
                  <td className="small">{post?.office?.name || "직접등록"}</td>
                  <td>
                    {post.creatorUser ? (
                      <>
                        {post.creatorUser.name}
                        {/* <br />(
                        {USER_LEVEL_TO_LABEL[post.creatorUser.level] || "-"}) */}
                      </>
                    ) : (
                      post.ownerName
                    )}
                  </td>
                  <td>
                    <SmallButton
                      text="조회"
                      color="beige"
                      width={64}
                      onClick={() => {
                        r__setPostPhoneModalInfo({
                          creatorPhone: post.creatorUser?.phone || undefined,
                          ownerPhone: post.ownerPhone || undefined,
                          customerPhone: post.customerPhone || undefined,
                        });
                      }}
                    />
                  </td>
                  <td
                    className="small"
                    style={
                      post.tradeStatus === EPostTradeStatus.DONE
                        ? { color: "var(--border-gray)" }
                        : {}
                    }
                  >
                    {post.tradeStatus === EPostTradeStatus.ING ? (
                      "거래중"
                    ) : post.tradeStatus === EPostTradeStatus.DONE ? (
                      "거래완료"
                    ) : (
                      <SmallButton
                        text="거래완료"
                        color="yellow-dark"
                        width={100}
                        onClick={() => {
                          r__setConfirmModalInfo({
                            title: "거래완료",
                            desc: [
                              `${post.apart.kaptName} ${post.dong}동 ${post.ho}호`,
                              "거래완료 처리하시겠습니까?",
                            ],
                            onClickBtnLeft: () => r__setConfirmModalInfo(null),
                            onClickBtnRight: () => {
                              done(post.id);
                            },
                          });
                        }}
                      />
                    )}
                  </td>

                  <td>
                    <div
                      className="flex-row-center-center"
                      style={{ gap: "10px" }}
                    >
                      <SmallButton
                        text="상세"
                        color="beige"
                        width={64}
                        onClick={() => {
                          r__setPostDetailModalInfo({ postId: post.id });
                        }}
                      />

                      <Switch
                        value={post.isActive ? "on" : "off"}
                        onClick={(changed) => {
                          if (post.isActive && changed === "on") return;
                          if (!post.isActive && changed === "off") return;
                          api_postActivate(post.id, !post.isActive).then(
                            (res) => {
                              if (!res) return;
                              setList((prev) => {
                                const copied = prev.slice();
                                copied[i].isActive = !post.isActive;
                                if (res.newTradeStatus)
                                  copied[i].tradeStatus = res.newTradeStatus;
                                return copied;
                              });
                            }
                          );
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default PostListPage;
