import { TBadge, TOfficeBadgeListItem } from "../lib/types/badge.type";
import { TListQuery } from "../lib/types/common.type";
import axios from "./axios";

export const api_badgePendingList = async (query: TListQuery) => {
  const res = await axios.get("/office-badge/pending", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TOfficeBadgeListItem[]; totalCount?: number };
};

export const api_badgeAccept = async (id: string) => {
  const res = await axios.post(`/office-badge/${id}/accept`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};

export const api_badgeSms = async (id: string) => {
  const res = await axios.post(`/office-badge/${id}/sms`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};

export const api_findBadge = async (officeId: string, apartId: string) => {
  const res = await axios.get(`/office-badge/find`, {
    params: { officeId, apartId },
  });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { badge: TBadge | null };
};
