import { useCallback, useState } from "react";
import ModalBase from "./Base";
import { useRecoilState } from "recoil";
import { atom__searchOfficeModalInfo } from "../../lib/recoil/modal.atom";
import BaseButton from "../Button/Base";
import cancelWhite from "../../assets/cancel_white.png";
import iconSearch from "../../assets/search.png";
import { api_officeSearch } from "../../api/office";
import { TOfficeSearchResultItem } from "../../lib/types/office.type";

const ModalSearchOffice = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__searchOfficeModalInfo
  );

  const [keyword, setKeyword] = useState("");

  const [result, setResult] = useState<TOfficeSearchResultItem[]>([]);
  const [selectedIdx, setSelectedIdx] = useState(-1);

  const close = useCallback(() => {
    setKeyword("");
    setResult([]);
    setSelectedIdx(-1);
    r__setModalInfo(null);
  }, []);

  const search = () => {
    if (!keyword) {
      setResult([]);
      setSelectedIdx(-1);
      return;
    }

    api_officeSearch(keyword).then((res) => {
      if (!res) return;
      setResult(res.list);
    });
  };

  const select = () => {
    if (!r__modalInfo) return;
    r__modalInfo.onSelectOffice(result[selectedIdx]);
    close();
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      {/* header */}
      <div
        className="flex-row-center-center"
        style={{
          height: "60px",
          width: "100%",
          backgroundColor: "var(--yellow-dark)",
          position: "absolute",
          left: 0,
          top: 0,
          borderTopLeftRadius: "2px",
          borderTopRightRadius: "2px",
        }}
      >
        <h2>중개사무소</h2>
        <div
          style={{
            position: "absolute",
            right: "5px",
            height: "38px",
            width: "38px",
            padding: "5px",
            cursor: "pointer",
          }}
          onClick={close}
        >
          <img
            src={cancelWhite}
            alt=""
            style={{ height: "28px", width: "28px" }}
          />
        </div>
      </div>
      <div style={{ height: "60px", width: "100%" }} />

      {/* search bar */}
      <div
        className="flex-row-center-center search-container"
        style={{ borderRadius: "8px", marginBottom: "36px" }}
      >
        <input
          className="search-input"
          style={{ flex: 1 }}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Enter") search();
          }}
        />
        <img src={iconSearch} onClick={search} />
      </div>

      {/* result */}
      <div className="search-office-result-wrapper">
        {!keyword.length && !result.length && (
          <div className="flex-row-center-center" style={{ height: "100%" }}>
            <p
              style={{
                fontSize: "24px",
                fontWeight: 400,
                lineHeight: "28.64px",
                textAlign: "center",
                color: "#B7BBBD",
              }}
            >
              부동산 이름을 검색해주세요.
            </p>
          </div>
        )}
        {result.map((r, i) => (
          <div
            key={i}
            className={`search-office-result ${
              selectedIdx === i ? "search-office-result-selected" : ""
            }`}
            onClick={() => setSelectedIdx(i)}
          >
            <p className="search-office-result-name">{r.name}</p>
            <p className="search-office-result-address">{r.address}</p>
          </div>
        ))}
      </div>

      <BaseButton text="선택" onClick={select} disabled={selectedIdx === -1} />
    </ModalBase>
  );
};

export default ModalSearchOffice;
