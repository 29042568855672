import { useRecoilState, useSetRecoilState } from "recoil";
import ModalBase from "./Base";
import {
  atom__complaintModalInfo,
  atom__confirmModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useCallback, useEffect, useState } from "react";
import CloseCircleButton from "../Button/CloseCircle";
import BaseButton from "../Button/Base";
import SmallButton from "../Button/Small";
import {
  api_complaintAnswer,
  api_complaintCancel,
  api_complaintDetail,
} from "../../api/complaint";

const ModalComplaint = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__complaintModalInfo
  );
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);

  const [reason, setReason] = useState("공동중개거부"); // 공동중개거부 | 허위매물등록
  const [isCancelled, setIsCancelled] = useState(false);
  const [content, setContent] = useState("");
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    if (!r__modalInfo) {
      setReason("");
      setIsCancelled(false);
      setContent("");
      setAnswer("");
      return;
    }

    api_complaintDetail(r__modalInfo.complaintId).then((res) => {
      if (!res) return;
      setReason(res.complaint.reason);
      setIsCancelled(!!res.complaint.canceledAt);
      setContent(res.complaint.content);
      setAnswer(res.complaint.answer || "");
    });
  }, [r__modalInfo]);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  const sendAnswer = async () => {
    if (!r__modalInfo) return;
    const res = await api_complaintAnswer(r__modalInfo.complaintId, { answer });
    if (!res) return;

    close();

    r__setSuccessModalInfo({
      desc: ["답변이 발송되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });
  };

  const confirmCancel = () => {
    r__setConfirmModalInfo({
      title: "신고 취소",
      desc: [
        "신고가 취소되고 누적신고수가 차감됩니다.",
        "취소를 진행하시겠습니까?",
      ],
      onClickBtnLeft: () => {
        r__setConfirmModalInfo(null);
      },
      onClickBtnRight: cancel,
      theme: "info",
    });
  };

  const cancel = async () => {
    if (!r__modalInfo) return;
    const res = await api_complaintCancel(r__modalInfo.complaintId);
    if (!res) return;

    setIsCancelled(true);

    r__setConfirmModalInfo(null);
    r__setSuccessModalInfo({ desc: ["신고를 성공적으로 취소하였습니다."] });

    r__modalInfo?.onCancel();

    setIsCancelled(true);
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>신고</h2>
        <CloseCircleButton onClick={close} />
      </div>

      <div className="flex-row-center-center" style={{ marginBottom: "30px" }}>
        <p className="label-small" style={{ width: "80px" }}>
          유형
        </p>
        <p style={{ flex: 1, textAlign: "center" }}>{reason}</p>
      </div>

      <div className="flex-row-center-center">
        <p className="label-small" style={{ width: "80px" }}>
          조치
        </p>
        <div className="flex-row-center-center" style={{ flex: 1 }}>
          <SmallButton
            color={"yellow-dark"}
            width={64}
            text={isCancelled ? "취소됨" : "취소"}
            disabled={isCancelled}
            onClick={confirmCancel}
          />
        </div>
      </div>

      <p
        className="label-small"
        style={{ textAlign: "left", marginTop: "30px" }}
      >
        신고내용
      </p>
      <textarea value={content} style={{ height: "150px" }} readOnly></textarea>

      <p
        className="label-small"
        style={{ textAlign: "left", marginTop: "30px" }}
      >
        답변
      </p>
      <textarea
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        style={{ height: "150px", marginBottom: "30px" }}
        placeholder="답변 입력"
        readOnly={isCancelled}
      ></textarea>

      <BaseButton
        text="답변 발송"
        onClick={sendAnswer}
        disabled={isCancelled}
      />
    </ModalBase>
  );
};

export default ModalComplaint;
