import { TFolder } from "../lib/types/folder.type";
import { TPostImage } from "../lib/types/post.type";
import axios from "./axios";

export const api_folderRetrieve = async (folderId: string) => {
  const res = await axios.get(`/folder/${folderId}/retrieve`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { subFolders: TFolder[]; files?: TPostImage[] };
};
