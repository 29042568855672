import { TAdminCreatePayload } from "../lib/types/admin.type";
import { TListQuery } from "../lib/types/common.type";
import {
  TBanner,
  TBannerField,
  TBannerListQuery,
  TNews,
  TNewsField,
  TNotice,
  TNoticeField,
} from "../lib/types/site.type";
import axios from "./axios";

export const api_newsCreate = async (payload: { newsList: TNewsField[] }) => {
  const res = await axios.post("/news", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_newsUpdate = async (id: string, payload: TNewsField) => {
  const res = await axios.put("/news/" + id, payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_newsDelete = async (ids: string[]) => {
  const res = await axios.delete("/news", { data: { ids } });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_newsList = async (query: TListQuery) => {
  const res = await axios.get("/news", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TNews[]; totalCount?: number };
};
export const api_newsDetail = async (id: string) => {
  const res = await axios.get("/news/" + id);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { news: TNews };
};

export const api_noticeCreate = async (payload: TNoticeField) => {
  const res = await axios.post("/notice", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_noticeUpdate = async (id: string, payload: TNoticeField) => {
  const res = await axios.put("/notice/" + id, payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_noticeDelete = async (ids: string[]) => {
  const res = await axios.delete("/notice", { data: { ids } });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_noticeList = async (query: TListQuery) => {
  const res = await axios.get("/notice", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TNotice[]; totalCount?: number };
};
export const api_noticeDetail = async (id: string) => {
  const res = await axios.get("/notice/" + id);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { notice: TNotice };
};

export const api_bannerCreate = async (
  payload: Omit<TBannerField, "isActive">
) => {
  const res = await axios.post("/banner", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_bannerUpdate = async (
  id: string,
  payload: Omit<TBannerField, "isActive">
) => {
  const res = await axios.put("/banner/" + id, payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_bannerActivate = async (id: string, isActive: boolean) => {
  const res = await axios.post(`/banner/${id}/activate`, { isActive });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_bannerList = async (query: TBannerListQuery) => {
  const res = await axios.get("/banner", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TBanner[]; totalCount?: number };
};
export const api_bannerDetail = async (id: string) => {
  const res = await axios.get("/banner/" + id);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { banner: TBanner };
};
