interface IProps {
  textOn?: string;
  textOff?: string;
  colorOn?: string;
  colorOff?: string;
  value: "on" | "off";
  onClick: (changed: "on" | "off") => void;
}
const Switch = (props: IProps) => {
  return (
    <div
      className={`flex-row-center-center switch-cointainer switch-${props.value}`}
    >
      <span
        className="flex-row-center-center"
        onClick={() => props.onClick("on")}
      >
        {props.textOn || "활성화"}
      </span>
      <span
        className="flex-row-center-center"
        onClick={() => props.onClick("off")}
      >
        {props.textOff || "비활성화"}
      </span>
    </div>
  );
};

export default Switch;
