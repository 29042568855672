import { useState } from "react";
import SmallButton from "../../components/Button/Small";
import { useNavigate } from "react-router-dom";
import Select from "../../components/Select";
import { TNewsField } from "../../lib/types/site.type";
import { useSetRecoilState } from "recoil";
import {
  atom__alertModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { api_newsCreate } from "../../api/site";

const NewsRegisterPage = () => {
  const navigate = useNavigate();

  const r__setAlertModalInfo = useSetRecoilState(atom__alertModalInfo);
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [selectedRegion, setSelectedRegion] = useState("전국");
  const [newsArr, setNewsArr] = useState<TNewsField[]>(
    Array.from({ length: 20 }, () => ({
      subject: "",
      url: "",
      source: "",
      region: "",
    }))
  );

  const register = async () => {
    const filtered = newsArr.filter((n) => n.source || n.subject || n.url);
    if (!filtered.length) {
      r__setAlertModalInfo({
        title: "실패",
        desc: ["하나 이상의 뉴스를 입력해주세요."],
      });
      return;
    }
    const bad = filtered.find((n) => !(n.source && n.subject && n.url));
    if (bad) {
      r__setAlertModalInfo({
        title: "실패",
        desc: ["제목/URL/출처 모두 입력해주세요."],
      });
      return;
    }

    const body = filtered.map((news) => ({ ...news, region: selectedRegion }));
    const res = await api_newsCreate({ newsList: body });
    if (!res) return;

    r__setSuccessModalInfo({
      desc: ["등록되었습니다."],
      onClickBtn: () => {
        r__setSuccessModalInfo(null);
        navigate("/site/news");
      },
    });
  };

  return (
    <div>
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <h2 style={{ marginRight: "30px" }}>뉴스 등록</h2>

        <div className="flex-row-center-center">
          <p className="label-small" style={{ marginRight: "10px" }}>
            카테고리
          </p>
          <Select
            data={[
              { label: "전국", value: "전국" },
              { label: "수원", value: "수원" },
            ]}
            value={selectedRegion}
            onChange={(v) => setSelectedRegion(v)}
          />

          <SmallButton
            style={{ marginLeft: "20px" }}
            text="등록"
            onClick={register}
            width={100}
            color="yellow-dark"
          />
        </div>
      </div>

      <div
        className="flex-row-center-center"
        style={{ gap: "20px", marginBottom: "20px", marginTop: "40px" }}
      >
        <div style={{ width: "50px" }}></div>
        <div style={{ flex: 1 }}>
          <p className="text-regular">제목</p>
        </div>
        <div style={{ width: "200px" }}>
          <p className="text-regular">URL</p>
        </div>
        <div style={{ width: "200px" }}>
          <p className="text-regular">출처</p>
        </div>
      </div>

      {newsArr.map((news, i) => (
        <div
          className="flex-row-center-center"
          style={{ gap: "20px", marginBottom: "20px" }}
        >
          <div style={{ width: "50px" }}>
            <p className="modal-desc">#{i + 1}</p>
          </div>
          <div style={{ flex: 1 }}>
            <input
              type="text"
              maxLength={50}
              value={news.subject}
              onChange={(e) => {
                setNewsArr((prev) => {
                  const copied = prev.slice();
                  copied[i].subject = e.target.value;
                  return copied;
                });
              }}
            />
          </div>
          <div style={{ width: "200px" }}>
            <input
              type="text"
              maxLength={300}
              value={news.url}
              onChange={(e) => {
                setNewsArr((prev) => {
                  const copied = prev.slice();
                  copied[i].url = e.target.value;
                  return copied;
                });
              }}
            />
          </div>
          <div style={{ width: "200px" }}>
            <input
              type="text"
              maxLength={50}
              value={news.source}
              onChange={(e) => {
                setNewsArr((prev) => {
                  const copied = prev.slice();
                  copied[i].source = e.target.value;
                  return copied;
                });
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewsRegisterPage;
