import { atom } from "recoil";
import { TOfficeSearchResultItem } from "../types/office.type";

export const atom__successModalInfo = atom<{
  title?: string;
  desc: string[];
  onClickBtn?: () => void;
  btnText?: string;
} | null>({
  key: "successModalInfo",
  default: null,
});

export const atom__alertModalInfo = atom<{
  title: string;
  desc: string[];
  onClickBtn?: () => void;
  btnText?: string;
} | null>({
  key: "alertModalInfo",
  default: null,
});

export const atom__confirmModalInfo = atom<{
  title: string;
  desc: string[];
  onClickBtnLeft: () => void;
  onClickBtnRight: () => void;
  btnLeftText?: string;
  btnRightText?: string;
  theme?: "alert" | "info";
} | null>({
  key: "confirmModalInfo",
  default: null,
});

export const atom__adminCreateModalInfo = atom<{ onCreate: () => void } | null>(
  {
    key: "adminCreateModalInfo",
    default: null,
  }
);

export const atom__salesCreateModalInfo = atom<{ onCreate: () => void } | null>(
  {
    key: "salesCreateModalInfo",
    default: null,
  }
);

export const atom__paperViewModalInfo = atom<{
  paperUrl1: string | null;
  paperUrl2: string;
  paperUrl3: string | null;
} | null>({
  key: "paperViewModalInfo",
  default: null,
});

export const atom__userRejectModalInfo = atom<{
  userId: string;
  onReject: () => void;
} | null>({
  key: "userRejectModalInfo",
  default: null,
});

export const atom__searchOfficeModalInfo = atom<{
  onSelectOffice: (selected: TOfficeSearchResultItem) => void;
} | null>({
  key: "searchOfficeModalInfo",
  default: null,
});

export const atom__complaintModalInfo = atom<{
  complaintId: string;
  onCancel: () => void;
} | null>({
  key: "complaintModalInfo",
  default: null,
});

export const atom__membershipModalInfo = atom<{
  membershipId: string;
  onAccept?: () => void;
} | null>({
  key: "membershipModalInfo",
  default: null,
});

export const atom__officeModalInfo = atom<{
  officeId: string | null;
  onCreate?: () => void;
  onEdit?: () => void;
} | null>({
  key: "officeModalInfo",
  default: null,
});

export const atom__depositCreateModalInfo = atom<{
  onCreate: () => void;
} | null>({
  key: "depositCreateModalInfo",
  default: null,
});

export const atom__bannerModalInfo = atom<{
  bannerId: string | null;
  onCreate?: () => void;
  onEdit?: () => void;
} | null>({
  key: "bannerModalInfo",
  default: null,
});

export const atom__newsEditModalInfo = atom<{
  newsId: string;
  onEdit: () => void;
} | null>({
  key: "newsEditModalInfo",
  default: null,
});

export const atom__noticeModalInfo = atom<{
  noticeId: string | null;
  onCreate?: () => void;
  onEdit?: () => void;
} | null>({
  key: "noticeModalInfo",
  default: null,
});

export const atom__postPhoneModalInfo = atom<{
  creatorPhone?: string;
  ownerPhone?: string;
  customerPhone?: string;
} | null>({
  key: "postPhoneModalInfo",
  default: null,
});

export const atom__postRejectModalInfo = atom<{
  postId: string;
  onReject: () => void;
} | null>({
  key: "postRejectModalInfo",
  default: null,
});

export const atom__postDetailModalInfo = atom<{
  postId: string;
} | null>({
  key: "postDetailModalInfo",
  default: null,
});

export const atom__loading = atom<boolean>({
  key: "loading",
  default: false,
});
