import { useCallback } from "react";
import ModalBase from "./Base";
import { useRecoilState } from "recoil";
import { atom__confirmModalInfo } from "../../lib/recoil/modal.atom";
import BaseButton from "../Button/Base";
import BaseButtonOutline from "../Button/Outline";
import iconAlert from "../../assets/alert.png";
import iconInfo from "../../assets/info.png";

const ModalConfirm = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__confirmModalInfo
  );

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);
  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-col-center-center">
        <img
          src={r__modalInfo?.theme === "info" ? iconInfo : iconAlert}
          style={{ width: "48px", height: "48px", marginBottom: "10px" }}
        />
        <h2 style={{ marginBottom: "20px" }}>{r__modalInfo?.title}</h2>
        <div style={{ marginBottom: "40px" }}>
          {r__modalInfo?.desc.map((d, i) => (
            <p key={i} className="modal-desc">
              {d}
            </p>
          ))}
        </div>

        <div className="flex-row-between-center">
          <BaseButtonOutline
            onClick={r__modalInfo?.onClickBtnLeft ?? close}
            text={r__modalInfo?.btnLeftText ?? "취소"}
            style={{
              borderColor: "var(--border-gray)",
              color: "var(--border-gray)",
              marginRight: "20px",
              width: "163px",
            }}
          />
          <BaseButton
            onClick={r__modalInfo?.onClickBtnRight ?? close}
            text={r__modalInfo?.btnRightText ?? "확인"}
            style={{
              flex: 1,
              backgroundColor:
                r__modalInfo?.theme === "info"
                  ? "var(--yellow-dark)"
                  : "var(--tomato)",
              width: "163px",
            }}
          />
        </div>
      </div>
    </ModalBase>
  );
};

export default ModalConfirm;
