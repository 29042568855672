import { useState } from "react";
import search from "../assets/search.png";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  onClickSearch: (keyword: string, attribute: string) => void;
  attributes: string[];
  placeholder?: string;
}

const SearchInput = (props: IProps) => {
  const [keyword, setKeyword] = useState("");

  const [selectedAttribute, setSelectedAttribute] = useState(
    props.attributes[0] ?? "구분"
  );

  return (
    <div className="flex-row-center-center search-container">
      <div className="select-container">
        <select
          onChange={(e) => setSelectedAttribute(e.target.value)}
          value={selectedAttribute}
        >
          {props.attributes.map((att, i) => (
            <option key={att} value={att}>
              {att}
            </option>
          ))}
        </select>
        <span>
          <FontAwesomeIcon icon={faCaretDown} color="var(--yellow-dark)" />
        </span>
      </div>
      <input
        className="search-input"
        style={{ flex: 1 }}
        placeholder={props.placeholder}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        onKeyDown={(e) => {
          if (e.code === "Enter")
            props.onClickSearch(keyword, selectedAttribute);
        }}
      />
      <img
        src={search}
        onClick={() => props.onClickSearch(keyword, selectedAttribute)}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export default SearchInput;
