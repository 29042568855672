import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import { printFormattedDate } from "../../lib/util";
import SmallButton from "../../components/Button/Small";
import { useSetRecoilState } from "recoil";
import { atom__officeModalInfo } from "../../lib/recoil/modal.atom";
import { api_officeList } from "../../api/office";
import Pagination from "../../components/Table/Pagination";
import { TOffice } from "../../lib/types/office.type";
import iconBadgeGold from "../../assets/medal_gold.png";
import iconBadgeSilver from "../../assets/medal_silver.png";
import iconBadgeBronze from "../../assets/medal_bronze.png";

const OfficeListPage = () => {
  const r__setOfficeModalInfo = useSetRecoilState(atom__officeModalInfo);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<
    (TOffice & { badge: { worth: number; endDate: string } | null })[]
  >([]);

  const limit = useMemo(() => 10, []);
  const refSearchAttribute = useRef("");
  const refSearchKeyword = useRef("");

  useEffect(() => {
    _fetch({ page: 1, needTotalCount: "y" });
  }, []);

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
  }) => {
    api_officeList({
      from: limit * (page - 1),
      limit,
      searchField: refSearchAttribute.current || undefined,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  const openModal = (id?: string) => {
    // 생성
    if (!id) {
      r__setOfficeModalInfo({
        officeId: null,
        onCreate: () => _fetch({ page: currentPage, needTotalCount: "y" }),
      });
      return;
    }
    // 수정
    r__setOfficeModalInfo({
      officeId: id,
      onEdit: () => _fetch({ page: currentPage }),
    });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>사무소 목록</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {
              refSearchAttribute.current = attribute;
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            attributes={["사무소명", "등록번호"]}
          />
        </div>

        <SmallButton
          text="생성"
          onClick={() => {
            openModal();
          }}
          width={100}
          color="beige"
        />
      </div>

      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <Th text="사무소명" sort="none" width={250} />
            <Th text="등록번호" sort="none" width={150} />
            <Th text="주소" sort="none" width={300} />
            <Th text="대표" sort="none" width={100} />
            <Th text="대표번호" sort="none" width={180} />
            <Th text="등록일" sort="none" width={150} />
            <Th text="배지" sort="none" width={200} />
            <Th text="관리" sort="none" />
          </thead>
          <tbody>
            {list.map((office) => {
              return (
                <tr key={office.id}>
                  <td className="small">{office.name}</td>
                  <td className="small">{office.registerNumber}</td>
                  <td className="small">{office.address}</td>

                  <td>{office.ceo}</td>
                  <td>{office.hp ?? "-"}</td>

                  <td className="small">
                    {printFormattedDate(new Date(office.createdAt))}
                  </td>

                  <td className="small">
                    {!!office.badge ? (
                      <div className="flex-row-center-center">
                        <img
                          src={
                            office.badge.worth === 50
                              ? iconBadgeGold
                              : office.badge.worth === 30
                              ? iconBadgeSilver
                              : iconBadgeBronze
                          }
                          style={{ width: "28px", marginRight: "8px" }}
                        />
                        <span>
                          만료:{" "}
                          {printFormattedDate(new Date(office.badge.endDate))}
                        </span>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td>
                    <div
                      className="flex-row-center-center"
                      style={{ gap: "10px" }}
                    >
                      <SmallButton
                        text="수정"
                        color="yellow-dark"
                        width={64}
                        onClick={() => {
                          openModal(office.id);
                        }}
                      />

                      {/* <SmallButton
                  text="삭제"
                  color="tomato"
                  width={64}
                  onClick={() => {
                    r__setConfirmModalInfo({
                      title: "삭제",
                      desc: [
                        "해당 사무소 정보가 삭제됩니다.",
                        "정말로 삭제하시겠습니까?",
                      ],
                      onClickBtnLeft: () => r__setConfirmModalInfo(null),
                      onClickBtnRight: () => {
                        deleteOffice("asdf");
                      },
                    });
                  }}
                /> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default OfficeListPage;
