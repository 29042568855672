import axios from "./axios";

export const api_fileUploadPrepare = async (filename: string) => {
  const res = await axios.post("/file/upload/prepare", {
    filenames: [filename],
  });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {
    preparedFiles: { id: string; signedUrl: string; url: string }[];
  };
};

export const api_fileUploadCommit = async (fileId: string) => {
  const res = await axios.post("/file/upload/commit", { ids: [fileId] });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};
