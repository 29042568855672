export type TSNBMenuItem = {
  /** 메뉴이름 */
  name: string;
  /** 경로 */
  path: string;
};
type TSNBMenu = {
  /** 메뉴그룹 이름 */
  category: string;
  /** 메뉴그룹 경로 */
  path: string;
  /** 메뉴들 */
  items: TSNBMenuItem[];
};

/** 메뉴 */
export const SNB_MENU: { SUPER_ADMIN: TSNBMenu[]; ADMIN: TSNBMenu[] } = {
  SUPER_ADMIN: [
    {
      category: "인원 관리",
      path: "/account",
      items: [
        { name: "운영진 계정 관리", path: "/account/admin" },
        { name: "영업직 계정 관리", path: "/account/sales" },
        { name: "기록 관리", path: "/log" },
      ],
    },
  ],
  ADMIN: [
    {
      category: "이용자 관리",
      path: "/user",
      items: [
        { name: "가입 요청", path: "/user/approval" },
        { name: "이용자 목록", path: "/user/list" },
        { name: "사무소 변경 요청", path: "/user/office-change" },
        { name: "신고 관리", path: "/user/complaint" },
        { name: "배지 신청 관리", path: "/user/badge" },
        { name: "멤버십 신청 관리", path: "/user/membership" },
      ],
    },
    {
      category: "중개사무소 관리",
      path: "/office",
      items: [{ name: "사무소 목록", path: "/office/list" }],
    },
    {
      category: "매물 관리",
      path: "/post",
      items: [
        { name: "매물 목록", path: "/post/list" },
        { name: "매물 등록 요청", path: "/post/pending" },
        { name: "이미지 관리", path: "/post/image" },
      ],
    },
    {
      category: "입금 관리",
      path: "/deposit",
      items: [{ name: "입금내역", path: "/deposit/list" }],
    },
    {
      category: "사이트 관리",
      path: "/site",
      items: [
        { name: "배너 목록", path: "/site/banner" },
        { name: "뉴스", path: "/site/news" },
        { name: "공지사항", path: "/site/notice" },
        { name: "기록 관리", path: "/log" },
      ],
    },
  ],
};
