import { TDefaultEntity } from "./common.type";
import { TOffice } from "./office.type";
import { TUser } from "./user.type";

/** 매물 승인상태 */
export enum EPostStatus {
  PENDING = "pending",
  ACCEPT = "accept",
  REJECT = "reject",
}

/** 매물 거래상태 */
export enum EPostTradeStatus {
  /** 거래중 */
  ING = "ing",
  /** 거래완료 요청됨 */
  DONE_REQUESTED = "done_requested",
  /** 거래완료됨 */
  DONE = "done",
}

export type TPostImage = {
  preparedUrl: string;
  url: string | null;
  urlW: string | null;
  url480: string | null;
  url480W: string | null;

  fullname: string;
};

export type TPost = TDefaultEntity & {
  areaType: string;
  pyeong: number;

  exclusiveArea: number;
  commonArea: number;

  dong: string;
  ho: string;
  floor: string;

  isLiveCustomer: boolean;
  customerPhone: string | null;

  manageFeeAvg: number;
  manageFeeName: string;

  cntRoom: number;
  cntBath: number;
  window: string; // 북향,북동향,동향,남동향,남향,남서향,서향,북서향

  saleKind: string; // 매매, 전세, 월세
  costOnce: number;
  costMonth: number;
  enterableDate: Date | null;
  mortgage: string; // 없음, 30% 미만, 30% 이상
  canInsurance: boolean;

  introduceTitle: string;
  introduceContent: string;

  isActive: boolean;
  status: EPostStatus;
  tradeStatus: EPostTradeStatus;
  rejectReason: string | null;

  ownerName: string | null;
  ownerPhone: string | null;

  creatorUserId: string | null;
  masterUserId: string | null;
  apartId: string;
  officeId: string | null;

  creatorUser: TUser;
  masterUser: TUser;
  office: TOffice;
  apart: {
    kaptCode: string;
    kaptName: string;
    kaptTopFloor: string;
    kaptAddr: string;
  };

  images: TPostImage[];
};

export type TPostPendingListItem = Pick<
  TPost,
  | "id"
  | "createdAt"
  | "apartId"
  | "apart"
  | "dong"
  | "ho"
  | "creatorUserId"
  | "creatorUser"
  | "masterUserId"
  | "masterUser"
  | "ownerName"
  | "ownerPhone"
  | "customerPhone"
>;

export type TPostListItem = Pick<
  TPost,
  | "id"
  | "createdAt"
  | "apartId"
  | "apart"
  | "dong"
  | "ho"
  | "creatorUserId"
  | "creatorUser"
  | "masterUserId"
  | "masterUser"
  | "ownerName"
  | "ownerPhone"
  | "customerPhone"
  | "isActive"
  | "status"
  | "tradeStatus"
>;
