import { useRecoilState, useSetRecoilState } from "recoil";
import ModalBase from "./Base";
import {
  atom__postRejectModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useCallback, useState } from "react";
import CloseCircleButton from "../Button/CloseCircle";
import BaseButton from "../Button/Base";
import { api_postReject } from "../../api/post";

const ModalPostReject = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__postRejectModalInfo
  );
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [reason, setReason] = useState("");

  const close = useCallback(() => {
    r__setModalInfo(null);
    setReason("");
  }, []);

  const reject = async () => {
    if (!r__modalInfo) return;

    const res = await api_postReject(r__modalInfo.postId, {
      rejectReason: reason,
    });
    if (!res) return;

    close();

    r__setSuccessModalInfo({
      desc: ["매물등록이 반려되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });

    r__modalInfo.onReject();
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>등록 반려</h2>
        <CloseCircleButton onClick={close} />
      </div>

      <p className="modal-desc">선택된 매물의 등록이 반려됩니다.</p>
      <p className="modal-desc">반려 사유를 입력해주세요.</p>
      <p className="modal-desc">*해당 반려사유는 입력한 문자로 전송됩니다.</p>

      <textarea
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        style={{ margin: "30px 0" }}
        placeholder="등록 반려사유 입력"
      ></textarea>

      <BaseButton text="반려" onClick={reject} disabled={reason.length < 2} />
    </ModalBase>
  );
};

export default ModalPostReject;
