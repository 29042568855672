import { useRecoilState, useSetRecoilState } from "recoil";
import ModalBase from "./Base";
import {
  atom__salesCreateModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useCallback, useEffect, useState } from "react";
import CloseCircleButton from "../Button/CloseCircle";
import BaseButton from "../Button/Base";
import { api_salesCreate } from "../../api/sales";

const ModalSalesCreate = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__salesCreateModalInfo
  );
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [uid, setUid] = useState("");
  const [pw, setPw] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [canNext, setCanNext] = useState(false);

  const close = useCallback(() => {
    r__setModalInfo(null);
    setUid("");
    setPw("");
    setName("");
    setPhone("");
  }, []);

  useEffect(() => {
    setCanNext(
      uid.length > 4 && pw.length > 8 && name.length > 2 && phone.length === 11
    );
  }, [uid, pw, name, phone]);

  const create = async () => {
    const res = await api_salesCreate({
      uid: uid.trim(),
      pw: pw.trim(),
      name: name.trim(),
      phone: phone.trim(),
    });

    if (!res) return;

    r__modalInfo?.onCreate();
    close();

    r__setSuccessModalInfo({
      desc: ["영업직 계정이 성공적으로 생성되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>영업직 계정 생성</h2>
        <CloseCircleButton onClick={close} />
      </div>

      <label htmlFor="uid">아이디</label>
      <input
        id="uid"
        type="text"
        placeholder="영문, 숫자만 포함하여 4~10자"
        maxLength={10}
        style={{ marginBottom: "20px" }}
        value={uid}
        onChange={(e) => {
          setUid(e.target.value);
        }}
      />
      <label htmlFor="pw">비밀번호</label>
      <input
        id="pw"
        type="password"
        placeholder="영문, 특수문자 포함하여 8~20자"
        maxLength={20}
        style={{ marginBottom: "20px" }}
        value={pw}
        onChange={(e) => {
          setPw(e.target.value);
        }}
      />
      <label htmlFor="name">이름</label>
      <input
        id="name"
        type="text"
        placeholder="이름 입력"
        style={{ marginBottom: "20px" }}
        value={name}
        maxLength={10}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <label htmlFor="phone">전화번호</label>
      <input
        id="phone"
        type="text"
        placeholder="'-' 제외하고 숫자만 입력"
        style={{ marginBottom: "40px" }}
        maxLength={11}
        value={phone}
        onChange={(e) => {
          setPhone(e.target.value);
        }}
      />

      <BaseButton text="생성" onClick={create} disabled={!canNext} />
    </ModalBase>
  );
};

export default ModalSalesCreate;
