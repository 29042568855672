import { useRecoilState, useSetRecoilState } from "recoil";
import ModalBase from "./Base";
import {
  atom__depositCreateModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useCallback, useEffect, useState } from "react";
import CloseCircleButton from "../Button/CloseCircle";
import BaseButton from "../Button/Base";
import { regNumber } from "../../lib/validator";
import { api_depositCreate } from "../../api/deposit";

const ModalDepositCreate = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__depositCreateModalInfo
  );
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [bank, setBank] = useState("");
  const [account, setAccount] = useState("");
  const [amount, setAmount] = useState("");
  const [memo, setMemo] = useState("");

  const [canNext, setCanNext] = useState(false);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  // 닫혔을 때 인풋 초기화
  useEffect(() => {
    if (r__modalInfo) return;

    setDate("");
    setName("");
    setBank("");
    setAccount("");
    setAmount("");
    setMemo("");
  }, [r__modalInfo]);

  useEffect(() => {
    setCanNext(
      !!date.length &&
        name.length > 2 &&
        bank.length > 2 &&
        amount.length > 3 &&
        memo.length > 1
    );
  }, [date, name, bank, amount, memo]);

  const create = async () => {
    const res = await api_depositCreate({
      name,
      bank,
      account: account || null,
      amount: Number(amount),
      memo,
      depositDate: date,
    });
    if (!res) return;

    r__modalInfo?.onCreate();
    close();

    r__setSuccessModalInfo({
      desc: ["입금내역이 정상적으로 입력되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>입금 내역 입력</h2>
        <CloseCircleButton onClick={close} />
      </div>

      <label htmlFor="date">입금일</label>
      <input
        id="date"
        type="date"
        style={{ marginBottom: "20px" }}
        value={date}
        onChange={(e) => {
          setDate(e.target.value);
        }}
      />

      <label htmlFor="name">입금자</label>
      <input
        id="name"
        type="text"
        placeholder="입금자명 기재"
        maxLength={10}
        style={{ marginBottom: "20px" }}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />

      <label htmlFor="bank">은행</label>
      <input
        id="bank"
        type="text"
        placeholder="은행명 기재"
        maxLength={10}
        style={{ marginBottom: "20px" }}
        value={bank}
        onChange={(e) => {
          setBank(e.target.value);
        }}
      />

      <label htmlFor="account">계좌 (선택)</label>
      <input
        id="account"
        type="text"
        placeholder="숫자만 입력 가능"
        maxLength={30}
        style={{ marginBottom: "20px" }}
        value={account}
        onChange={(e) => {
          if (e.target.value.length && !regNumber.test(e.target.value)) return;
          setAccount(e.target.value);
        }}
      />

      <label htmlFor="amount">금액 (원)</label>
      <input
        id="amount"
        type="number"
        step={1}
        placeholder="숫자만 입력 가능"
        maxLength={10}
        style={{ marginBottom: "20px" }}
        value={amount}
        onChange={(e) => {
          if (e.target.value.length && !regNumber.test(e.target.value)) return;
          setAmount(e.target.value);
        }}
      />

      <label htmlFor="memo">비고</label>
      <input
        id="memo"
        type="text"
        placeholder="입금 사유 등 입력"
        maxLength={100}
        style={{ marginBottom: "20px" }}
        value={memo}
        onChange={(e) => {
          setMemo(e.target.value);
        }}
      />

      <BaseButton text="입금 등록" onClick={create} disabled={!canNext} />
    </ModalBase>
  );
};

export default ModalDepositCreate;
