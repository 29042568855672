import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import { printFormattedDateTime } from "../../lib/util";
import Pagination from "../../components/Table/Pagination";
import { api_logAdmin } from "../../api/log";
import { TLogAdmin } from "../../lib/types/log.type";

const LogAdminListPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TLogAdmin[]>([]);

  const limit = useMemo(() => 10, []);
  const refSearchAttribute = useRef("");
  const refSearchKeyword = useRef("");

  useEffect(() => {
    _fetch({
      page: 1,
      needTotalCount: "y",
    });
  }, []);

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
    userKind?: string;
  }) => {
    api_logAdmin({
      from: limit * (page - 1),
      limit,
      searchField: refSearchAttribute.current || undefined,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>기록 관리</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {
              refSearchAttribute.current = attribute;
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            attributes={["담당자"]}
          />
        </div>
      </div>

      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <Th text="기록시간" sort="none" width={120} />
            <Th text="담당자" sort="none" width={100} />
            <Th text="대분류" sort="none" width={100} />
            <Th text="소분류" sort="none" width={100} />
            <Th text="행동" sort="none" width={100} />
            <Th text="비고" sort="none" width={300} />
          </thead>
          <tbody>
            {list.map((log) => {
              return (
                <tr key={log.id}>
                  <td className="small">
                    {printFormattedDateTime(new Date(log.createdAt))}
                  </td>
                  <td>{log.admName}</td>
                  <td>{log.topic1}</td>
                  <td>{log.topic2 ?? "-"}</td>
                  <td>{log.action}</td>
                  <td className="small">{log.memo ?? "-"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default LogAdminListPage;
