import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { atom__me } from "../../lib/recoil/common.atom";
import { SNB_MENU } from "./menu";
import SideBarItem from "./Item";
import logo from "../../assets/logo_275w.png";

const SideNavigationBar = () => {
  const pathName = useLocation().pathname;

  const r__me = useRecoilValue(atom__me);

  return (
    <section className="snb-wrapper">
      <img
        src={logo}
        style={{ width: "100%", objectFit: "contain", margin: "20px 0" }}
      />
      <ul className="snb-container">
        {(r__me?.level === 100 ? SNB_MENU.SUPER_ADMIN : SNB_MENU.ADMIN).map(
          (menuGroup, i) => (
            <div key={i}>
              <div className="snb-category">
                <span>{menuGroup.category}</span>
              </div>
              {menuGroup.items.map((m, i) => (
                <SideBarItem
                  key={m.path}
                  item={m}
                  isActive={pathName.includes(m.path)}
                />
              ))}
            </div>
          )
        )}
      </ul>
    </section>
  );
};

export default SideNavigationBar;
