import { TOffice } from "../types/office.type";
import { TDefaultEntity } from "./common.type";
import { TUserMembership } from "./membership.type";

export type TMe = {
  id: string;
  level: number;
  name: string;
};

export type TUserField = {
  uid: string;
  name: string;
  phone: string | null;
  email: string | null;
  level: number;

  lastLoginIp: string | null;
  lastLoginDate: Date | null;

  imageUrlBusiness: string | null;
  imageUrlOffice: string;
  imageUrlCert: string | null;
  officeHp: string | null;

  activeMembershipId: string | null;
  activeMembership?: TUserMembership;
  // activeMembershipStartDate: Date | null;
  // activeMembershipEndDate: Date | null;

  officeId: string | null;
  office?: TOffice;

  changingOfficeId: string | null;
  changingOffice?: TOffice;
  changingOfficeDate: string | null;
  changingImageUrlBusiness: string | null;
  changingImageUrlOffice: string | null;
  changingImageUrlCert: string | null;
};
export type TUser = TDefaultEntity & TUserField;

export const USER_LEVEL_TO_LABEL: Record<number, string> = {
  100: "개업공인중개사",
  70: "소속공인중개사",
  40: "중개보조원",
  20: "영업직원",
};
export const USER_LEVEL: Record<string, number> = {
  개업공인중개사: 100,
  소속공인중개사: 70,
  중개보조원: 40,
  영업직원: 20,
};
