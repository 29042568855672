export const printFormattedDate = (
  date: Date | null,
  options?: { seperator?: string }
) => {
  if (!date) return "";

  const seperator = options?.seperator ?? ".";

  const y = date.getFullYear();
  const M = (date.getMonth() + 1 + "").padStart(2, "0");
  const d = (date.getDate() + "").padStart(2, "0");
  return `${y}${seperator}${M}${seperator}${d}`;
};
export const printFormattedDateTime = (
  date: Date | null,
  options?: { dateSeperator?: string; timeSeperator?: string }
) => {
  if (!date) return "";
  const strDate = printFormattedDate(date, {
    seperator: options?.dateSeperator,
  });

  const seperator = options?.timeSeperator ?? ":";

  const h = (date.getHours() + "").padStart(2, "0");
  const m = (date.getMinutes() + "").padStart(2, "0");
  const s = (date.getSeconds() + "").padStart(2, "0");

  return `${strDate} ${h}${seperator}${m}${seperator}${s}`;
};

export const printFormattedDateKr = (date: Date | null) => {
  if (!date) return "";

  const y = date.getFullYear();
  const M = (date.getMonth() + 1 + "").padStart(2, "0");
  const d = (date.getDate() + "").padStart(2, "0");
  return `${y}년 ${M}월 ${d}일`;
};

export const insertComma = (origin: number) => {
  let isPlus = true;
  if (origin < 0) {
    isPlus = false;
  }
  const strMoney = Math.abs(origin).toString();
  const firstPartLength = strMoney.length % 3;
  const firstPart = strMoney.slice(0, firstPartLength);
  const rest = strMoney.slice(firstPartLength);

  if (!rest) return firstPart;

  let restPart = "";
  let cnt = 0;
  for (let i = 0; i < rest.length; i++) {
    restPart += rest[i];
    if (++cnt === 3) {
      cnt = 0;
      restPart += ",";
    }
  }
  restPart = restPart.substring(0, restPart.length - 1);
  const result = firstPart ? firstPart + "," + restPart : restPart;
  return isPlus ? result : "-" + result;
};

export const printCost = (
  manwon: number,
  options?: { manwonLabel?: string }
) => {
  const uk = Math.floor(manwon / 10000);
  const rest = manwon % 10000;
  if (!rest) return `${uk ? uk + "억 " : ""}`;

  const commaManwon = insertComma(rest);
  return `${uk ? uk + "억 " : ""}${commaManwon}${options?.manwonLabel || ""}`;
};

export const convertToPyeong = (size: number) => {
  return Math.floor(size / 3.3025);
};
