import { atom } from "recoil";
import { TMe } from "../types/user.type";

export const atom__me = atom<TMe | null>({
  key: "me",
  // default: {
  //   id: "asdf",
  //   name: "홍길동",
  //   level: 100,
  // },
  default: null,
});
