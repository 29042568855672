import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import { printFormattedDate } from "../../lib/util";
import Select from "../../components/Select";
import { api_userList } from "../../api/user";
import { TUser, USER_LEVEL_TO_LABEL } from "../../lib/types/user.type";
import Pagination from "../../components/Table/Pagination";

const UserListPage = () => {
  const [filterKind, setFilterKind] = useState("none"); // 'none' | '개업공인중개사' | '소속공인중개사' | '중개보조원'

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TUser[]>([]);

  const limit = useMemo(() => 10, []);
  const refSearchAttribute = useRef("");
  const refSearchKeyword = useRef("");

  useEffect(() => {
    _fetch({
      page: 1,
      needTotalCount: "y",
    });
  }, [filterKind]);

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
    userKind?: string;
  }) => {
    api_userList({
      from: limit * (page - 1),
      limit,
      searchField: refSearchAttribute.current || undefined,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
      userKind: filterKind !== "none" ? filterKind : undefined,
      withMembership: "y",
      status: ["accept"],
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>이용자 목록</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {
              refSearchAttribute.current = attribute;
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            attributes={["아이디", "이름", "전화번호"]}
          />
        </div>
        <Select
          data={[
            { label: "유형", value: "none" },
            { label: "개업공인중개사", value: "개업공인중개사" },
            { label: "소속공인중개사", value: "소속공인중개사" },
            { label: "중개보조원", value: "중개보조원" },
          ]}
          onChange={(v) => {
            setFilterKind(v);
          }}
          value={filterKind}
        />
      </div>

      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <Th text="가입일" sort="none" width={150} />
            <Th text="아이디" sort="none" width={200} />
            <Th text="이름" sort="none" width={100} />
            <Th text="전화번호" sort="none" />
            <Th text="유형" sort="none" width={180} />
            <Th text="사무소" sort="none" width={200} />
            <Th text="이메일" sort="none" />
            <Th text="멤버십" sort="none" />
          </thead>
          <tbody>
            {list.map((user) => {
              return (
                <tr key={user.id}>
                  <td>{printFormattedDate(new Date(user.createdAt))}</td>
                  <td>{user.uid}</td>
                  <td>{user.name}</td>
                  <td>{user.phone}</td>
                  <td>{USER_LEVEL_TO_LABEL[user.level] || "-"}</td>
                  <td className="small">{user.office?.name || "-"}</td>
                  <td className="small">{user.email}</td>
                  <td className="small">
                    {user.activeMembership?.membershipInfo.name || "-"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default UserListPage;
