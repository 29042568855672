import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import { printFormattedDate } from "../../lib/util";
import SmallButton from "../../components/Button/Small";
import { useSetRecoilState } from "recoil";
import { atom__bannerModalInfo } from "../../lib/recoil/modal.atom";
import Switch from "../../components/Switch";
import { TBanner } from "../../lib/types/site.type";
import Pagination from "../../components/Table/Pagination";
import { api_bannerActivate, api_bannerList } from "../../api/site";
import Checkbox from "../../components/Checkbox";

const BannerListPage = () => {
  const r__setBannerModalInfo = useSetRecoilState(atom__bannerModalInfo);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TBanner[]>([]);
  // ui용 (실제 api에 넘어가는 값은 reef)
  const [onlyInTerm, setOnlyInTerm] = useState(true);
  const [withActive, setWithActive] = useState(true);
  const [withInactive, setWithInactive] = useState(true);

  const limit = useMemo(() => 10, []);
  const strToday = useMemo(() => printFormattedDate(new Date()), []);
  const refSearchAttribute = useRef("");
  const refSearchKeyword = useRef("");
  const refToggleOnlyInTerm = useRef(true);
  const refToggleWithActive = useRef(true);
  const refToggleWithInactive = useRef(true);

  useEffect(() => {
    _fetch({ page: 1, needTotalCount: "y" });
  }, []);

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
  }) => {
    api_bannerList({
      from: limit * (page - 1),
      limit,
      searchField: refSearchAttribute.current || undefined,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
      onlyInTerm: refToggleOnlyInTerm.current ? "y" : "n",
      withActive: refToggleWithActive.current ? "y" : "n",
      withInactive: refToggleWithInactive.current ? "y" : "n",
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  const openModal = (id?: string) => {
    // 생성
    if (!id) {
      r__setBannerModalInfo({
        bannerId: null,
        onCreate: () => _fetch({ page: currentPage, needTotalCount: "y" }),
      });
      return;
    }
    // 수정
    r__setBannerModalInfo({
      bannerId: id,
      onEdit: () => _fetch({ page: currentPage }),
    });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>배너 목록</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {
              refSearchAttribute.current = attribute;
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            attributes={["광고주", "담당자", "광고대상물"]}
          />
        </div>

        <div className="flex-row-center-center">
          <Checkbox
            label="게시기간 이내만"
            checked={onlyInTerm}
            onClick={() => {
              const prev = refToggleOnlyInTerm.current;
              setOnlyInTerm(!prev);
              refToggleOnlyInTerm.current = !prev;
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
          />
          <Checkbox
            label="활성화"
            checked={withActive}
            onClick={() => {
              const prev = refToggleWithActive.current;
              setWithActive(!prev);
              refToggleWithActive.current = !prev;
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            style={{ margin: "0 10px" }}
          />
          <Checkbox
            label="비활성화"
            checked={withInactive}
            onClick={() => {
              const prev = refToggleWithInactive.current;
              setWithInactive(!prev);
              refToggleWithInactive.current = !prev;
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            style={{ marginRight: "10px" }}
          />
          <SmallButton
            text="등록"
            onClick={() => openModal()}
            width={100}
            color="beige"
          />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <tr>
              <Th text="광고대상물" sort="none" width={200} />
              <Th text="고객사" sort="none" width={200} />
              <Th text="광고주" sort="none" width={200} />
              <Th text="광고주 연락처" sort="none" width={200} />
              <Th text="게시기간" sort="none" width={200} />
              <Th text="관리" sort="none" />
            </tr>
          </thead>
          <tbody>
            {list.map((banner, i) => {
              const strStartDate = printFormattedDate(
                new Date(banner.startDate)
              );
              const strEndDate = printFormattedDate(new Date(banner.endDate));
              const isInTerm =
                strToday >= strStartDate && strToday <= strEndDate;

              return (
                <tr>
                  <td>{banner.target}</td>
                  <td>{banner.clientOrg}</td>
                  <td>{banner.clientName}</td>
                  <td>{banner.clientPhone}</td>
                  <td
                    className="small"
                    style={{
                      color:
                        banner.isActive && isInTerm
                          ? "var(--yellow-dark)"
                          : !banner.isActive && !isInTerm
                          ? "var(--border-gray)"
                          : "#000",
                    }}
                  >{`${strStartDate} - ${strEndDate}`}</td>
                  <td>
                    <div
                      className="flex-row-center-center"
                      style={{ gap: "10px" }}
                    >
                      <SmallButton
                        text="수정"
                        color="yellow-dark"
                        width={64}
                        onClick={() => {
                          openModal(banner.id);
                        }}
                      />

                      <Switch
                        value={banner.isActive ? "on" : "off"}
                        onClick={() => {
                          api_bannerActivate(banner.id, !banner.isActive).then(
                            (res) => {
                              if (!res) return;
                              setList((prev) => {
                                const copied = prev.slice();
                                copied[i].isActive = !banner.isActive;
                                return copied;
                              });
                            }
                          );
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default BannerListPage;
