import { useRecoilState, useSetRecoilState } from "recoil";
import ModalBase from "./Base";
import {
  atom__noticeModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useCallback, useEffect, useState } from "react";
import CloseCircleButton from "../Button/CloseCircle";
import BaseButton from "../Button/Base";
import {
  api_noticeCreate,
  api_noticeDetail,
  api_noticeUpdate,
} from "../../api/site";

const ModalNotice = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(atom__noticeModalInfo);
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  const [canNext, setCanNext] = useState(false);

  const close = useCallback(() => {
    r__setModalInfo(null);
    setSubject("");
    setContent("");
  }, []);

  const _fetch = () => {
    if (!r__modalInfo || !r__modalInfo.noticeId) return;

    api_noticeDetail(r__modalInfo.noticeId).then((res) => {
      if (!res) return;
      setSubject(res.notice.subject);
      setContent(res.notice.content);
    });
  };

  useEffect(() => {
    if (r__modalInfo?.noticeId) _fetch();
  }, [r__modalInfo]);

  useEffect(() => {
    setCanNext(subject.length > 3 && content.length > 8);
  }, [subject, content]);

  const create = async () => {
    const res = await api_noticeCreate({ subject, content });
    if (!res) return;

    r__modalInfo?.onCreate?.();
    close();

    r__setSuccessModalInfo({
      desc: ["등록되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });
  };
  const edit = async () => {
    if (!r__modalInfo?.noticeId) return;
    const res = await api_noticeUpdate(r__modalInfo.noticeId, {
      subject,
      content,
    });
    if (!res) return;

    r__modalInfo?.onEdit?.();
    close();

    r__setSuccessModalInfo({
      desc: ["수정되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>{r__modalInfo?.noticeId ? "공지사항 정보" : "공지사항 등록"}</h2>
        <CloseCircleButton onClick={close} />
      </div>

      <label htmlFor="subject">제목 (최대 30자)</label>
      <input
        id="subject"
        type="text"
        placeholder=""
        maxLength={30}
        style={{}}
        value={subject}
        onChange={(e) => {
          setSubject(e.target.value);
        }}
      />
      <div
        className="flex-row"
        style={{ justifyContent: "flex-end", marginBottom: "20px" }}
      >
        <p className="text-regular">{subject.length}/30</p>
      </div>

      <label htmlFor="content">내용 (최대 1,000자)</label>
      <textarea
        id="content"
        placeholder=""
        maxLength={1000}
        style={{}}
        value={content}
        onChange={(e) => {
          setContent(e.target.value);
        }}
      />
      <div
        className="flex-row"
        style={{ justifyContent: "flex-end", marginBottom: "30px" }}
      >
        <p className="text-regular">{content.length}/1000</p>
      </div>

      <BaseButton
        text={r__modalInfo?.noticeId ? "수정" : "등록"}
        onClick={r__modalInfo?.noticeId ? edit : create}
        disabled={!canNext}
      />
    </ModalBase>
  );
};

export default ModalNotice;
