import Axios from "axios";

// const BASE_URL = process.env.REACT_APP_API_URL;
const BASE_URL =
  window.location.protocol === "https:"
    ? "https://api.honeyaptdanji.com"
    : "http://localhost:5000";
// const BASE_URL = "https://api.honeyaptdanji.com";
// const BASE_URL = "https://api.honeyaptdanji.com";

const axios = Axios.create({
  baseURL: BASE_URL,
});

const token = localStorage.getItem("at-admin");

axios.defaults.headers.common = {
  Authorization: `Bearer ${token}`,
};
axios.defaults.withCredentials = true;

export default axios;
