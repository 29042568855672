import { useEffect, useState } from "react";
import { TFolder } from "../../lib/types/folder.type";
import { api_folderRetrieve } from "../../api/folder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import iconFolder from "../../assets/folder.png";
import { TPostImage } from "../../lib/types/post.type";

const PostImagePage = () => {
  const [history, setHistory] = useState<TFolder[]>([]);
  const [currentFolderId, setCurrentFolderId] = useState("root");

  const [subFolders, setSubFolders] = useState<TFolder[]>([]);
  const [files, setFiles] = useState<TPostImage[]>([]);

  useEffect(() => {
    _fetch({ folderId: currentFolderId });
  }, [currentFolderId]);

  const _fetch = ({ folderId }: { folderId: string }) => {
    api_folderRetrieve(folderId).then((res) => {
      if (!res) return;

      setSubFolders(res.subFolders);
      setFiles(res.files ?? []);
    });
  };

  const diveFolder = (targetFolder: TFolder) => {
    setHistory((prev) => {
      return [...prev, targetFolder];
    });
    setCurrentFolderId(targetFolder.id);
  };

  const moveFolderByHistoryIdx = (idx: number) => {
    setHistory((prev) => {
      return prev.slice(0, idx + 1);
    });
    setCurrentFolderId(history[idx].id);
  };

  return (
    <div>
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <h2>이미지 관리</h2>
      </div>

      <div
        className="flex-row-start-center"
        style={{
          flexWrap: "wrap",
          gap: "20px",
          margin: "36px 0 70px 0",
        }}
      >
        {history.map((h, i) => {
          return (
            <>
              {i !== 0 && (
                <FontAwesomeIcon
                  icon={faCaretRight}
                  style={{ height: "30px", marginRight: "10px" }}
                />
              )}
              <div
                className="flex-row-start-center"
                onClick={() =>
                  i === history.length - 1 ? {} : moveFolderByHistoryIdx(i)
                }
                style={i === history.length - 1 ? {} : { cursor: "pointer" }}
              >
                <img
                  src={iconFolder}
                  style={{ height: "24px", marginRight: "8px" }}
                />
                <span className="folder-name">{h.name}</span>
              </div>
            </>
          );
        })}
      </div>

      <div style={{ flex: 1, overflow: "auto" }}>
        {files.length > 0 && (
          <div
            className="flex-row"
            style={{
              width: "100%",
              flexWrap: "wrap",
              gap: "27px",
            }}
          >
            {files.map((f, i) => (
              <div key={i} style={{ marginBottom: "48px" }}>
                <img
                  src={f.url480 || f.preparedUrl}
                  style={{
                    width: "480px",
                    height: "260px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(f.url480 || f.preparedUrl, "_blank");
                  }}
                />
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16.71px",
                    textAlign: "right",
                    color: "#000",
                  }}
                >
                  {f.fullname}
                </p>
              </div>
            ))}
          </div>
        )}

        <div
          className="flex-row"
          style={{
            width: "100%",
            flexWrap: "wrap",
            gap: "36px",
          }}
        >
          {subFolders.map((f) => {
            return (
              <div
                className="flex-col-center-center"
                style={{
                  width: "200px",
                  cursor: "pointer",
                  marginBottom: "36px",
                }}
                onDoubleClick={() => {
                  diveFolder(f);
                }}
              >
                <img
                  src={iconFolder}
                  style={{ width: "60px", marginBottom: "15px" }}
                />
                <span className="folder-name">{f.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PostImagePage;
