import { TListQuery } from "../lib/types/common.type";
import { TComplaint } from "../lib/types/complaint.type";
import axios from "./axios";

export const api_complaintList = async (
  query: TListQuery & {
    reason?: string; // "공동중개거부" | "허위매물등록";
  }
) => {
  const res = await axios.get("/complaint", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TComplaint[]; totalCount?: number };
};

export const api_complaintDetail = async (id: string) => {
  const res = await axios.get("/complaint/" + id);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { complaint: TComplaint };
};

export const api_complaintAnswer = async (
  id: string,
  payload: { answer: string }
) => {
  const res = await axios.post(`/complaint/${id}/answer`, payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { complaint: TComplaint };
};
export const api_complaintCancel = async (id: string) => {
  const res = await axios.post(`/complaint/${id}/cancel`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { complaint: TComplaint };
};
